<template>
  <div>
    <div class="contact">
      <p class="title">联系我们</p>
      <img src="../../assets/qrcode.png" alt="">
      <!-- <p>扫描二维码</p> -->
      <p class="lasttip">微信搜索并关注<span>枫誉食品</span>公众号获取更多产品和购买信息</p>
    </div>
    <div class="footerbox">
      <ul>
        <li><router-link to="/index">品牌故事</router-link></li>
        <li><router-link to="/introduction">鲜冻海参</router-link></li>
        <li><router-link to="/dishes">名厨参谱</router-link></li>
      </ul>
      <p><a href="https://beian.miit.gov.cn/#/Integrated/index" target="_blank">沪ICP备2021003955号</a></p>
      <p>© 2020  All rights reserved.  Fengyu Trading ., Ltd</p>
    </div>
    <!-- <div class="gotop" style="" @click="gotop">
      <img src="../../assets/toprarrow.png" />
    </div> -->
  </div>
</template>
<script>
export default {
  data () {
   return {}
  },
  mounted() {},
  methods:{
    gotop() {
      window.scrollTo(0,0)
    }
  }
}
</script>
<style lang="scss" scoped>
  .contact {
    background: #E6E3DC;
    overflow: hidden;
    text-align: center;
    .title {
      font-size: 40px;
      color: #603E2D;
      margin-top: 177px;
    }
    img {
      margin-top: 50px;
      margin-bottom: 44px;
    }
    p {
      color: #221815;
      font-size: 16px;
      margin-bottom: 20px;
    }
    .lasttip {
      margin-bottom: 182px;
      span {
        color: #D62326;
        // background: red;
      }
    }
  }
  .footerbox {
    height: 356px;
    background: #A50000;
    text-align: center;
    color: #fff;
    overflow: hidden;
    font-size: 14px;
    ul {
      display: flex;
      justify-content: center;
      margin-top: 80px;
      margin: 80px auto 37px;
      li {
        margin:0 18px;
        a {
          color: #fff;
          font-size: 14px;
        }
      }
    }
    p {
      a {color: #fff;}
    }
  }
  .gotop {
    position: fixed;
    bottom:60px;
    right:60px;
    width: 40px;
    height: 40px;
    background: #ddd;
    text-align: center;
    img {
      width: 20px;
      height: 20px;
      vertical-align: center;
    }
  }
  @media screen and(max-width: 768px){
    .contact {
      background: #E6E3DC;
      overflow: hidden;
      text-align: center;
      .title {
        font-size: 20px;
        color: #603E2D;
        margin-top: 100px;
      }
      img {
        width: 120px;
        margin-top: 50px;
        margin-bottom: 20px;
      }
      p {
        color: #603E2D;
        font-size: 12px;
        margin-bottom: 0;
      }
      .lasttip {
        margin-bottom: 100px;
      }
    }
    .footerbox {
      height: 214px;
      background: #A50000;
      text-align: center;
      color: #fff;
      overflow: hidden;
      font-size: 12px;
      ul {
        display: flex;
        justify-content: center;
        margin: 80px auto 20px;
        li {
          margin:0 18px;
          a {
            color: #fff;
            font-size: 12px;
          }
        }
      }
    }
  }
</style>