<template>
  <div>
    <!-- <Headers :currentindex="2"/> -->
    <div class="banner">
      <p class="txt1">100%</p>
      <p class="txt2">纯</p>
      <p class="txt3">野生</p>
      <p class="txt4">加拿大没有人工养殖的海参，枫誉为您带来的全部是产自加拿大西海岸北纬51°海域的野生红刺参。</p>
    </div>
    <div class="goodsintr goodsintr1">
      <img src="../assets/goods1.png" alt="">
      <div class="txt">
        <p class="names">鲜冻红刺参（整参含桂花蚌）</p>
        <p class="ennames">Frozen whole red sea cucumber</p>
        <div class="te">
          <p>产地：加拿大 BC 省</p>
          <p>净重：454g</p>
          <p>工艺：原始淡干工艺</p>
        </div>
        <p class="intr">加拿大野生红刺参主产于加拿大西海岸纯净海域，由于海水温度极低，红参生长周期极长（最长可达10年以上），肉质肥厚，胶质充实，营养物质丰富。体壁上有五条特别粗壮的筋，又名“桂花蚌”，是红参的精华。加拿大红刺参是海参之王。 鲜冻红刺参是由潜水员捕捞上岸后，进行原生态加工、定型、固化蛋白质。食用时仍需煮40-60分钟不等，煮熟后凉拌或爆炒等放入各样菜品。</p>
      </div>
    </div>
    <div class="goodsintr goodsintrall goodsintr2">
      <div class="txt">
        <p class="btxt">金字塔尖的</p>
        <p class="bred">4.2<span class="sred">%</span></p>
        <p class="stxt">由于野生红刺参的珍稀属性，加拿大政府实施了严格的捕捞限制，每年仅能捕捞野生红刺参存量的4.2%。</p>
        <p class="bred">10<span class="sred">年</span></p>
        <p class="btxt">生长营养价值无限</p>
        <p class="stxt">加拿大野生红刺参，生长5年方能成年，枫誉野生红海参，生长周期一般为7年以上，拥有海参种类中最发达的海参筋，营养价值满溢。</p>
      </div>
      <img src="../assets/goods2.png" alt="">
    </div>
    <div class="goodsintr goodsintrall goodsintr3">
      <img src="../assets/goods3.png" alt="">
      <div class="txt">
        <p class="bred">30<span class="sred">分钟</span></p>
        <p class="btxt">锁定鲜活滋味</p>
        <p class="stxt ste">鲜冻技术，确保海参新鲜营养不流失</p>
        <p class="stxt">枫誉野生红刺参全部由人工潜水捕捞，捕捞船只靠岸后，必须在半个小时内完成初级加工、定型、固化蛋白质等工序。创新的鲜冻技术解决啦传统冷冻导致的细胞膜破坏、口感变差、营养成分下降等问题，最大限度地保留了海参的口感、营养。</p>
      </div>
    </div>
    <div class="goodsintr goodsintrall goodsintr4">
      <div class="txt">
        <p class="bred">16<span class="sred">种</span></p>
        <p class="te">氨基酸</p>
        <p class="btxt">极高营养价值</p>
        <p class="stxt">加拿大野生红刺参被誉为“氨基酸大富翁”，富含16种氨基酸(其中8种为人体不可自身合成)。</p>
        <p class="stxt">还含有丰富的天然牛磺酸成分、酸性粘多糖、硫酸软骨素、海参皂苷、多种维生素、微量元素、脂肪酸等营养物质。</p>
        <p class="stxt">加拿大红刺参参龄较长，生长周期一般在7年以上，参体更大、营养含量更高。</p>
      </div>
      <img src="../assets/goods4.png" alt="">
    </div>
    <div class="quality">
      <div>
        <img src="../assets/quality1.png" alt="">
        <img src="../assets/quality2.png" alt="">
      </div>
      <p>* 加拿大红刺参所含营养元素及其含量经权威检测机构SGS检测认证，数据准确度高。</p>
    </div>
    <div class="composition">
      <ul>
        <li>
          <div class="libox">
            <p class="names">精氨酸</p>
            <p class="ennames">Arginine</p>
            <p class="work">补血养血，补肾益精</p>
          </div>
        </li>
        <li>
          <div class="libox">
            <p class="names">海参皂甙</p>
            <p class="ennames">Sea cucumber saponin</p>
            <p class="work">天然药材，理想食疗</p>
          </div>
        </li>
        <li>
          <div class="libox">
            <p class="names">海参粘多糖</p>
            <p class="ennames">Sea cucumber mucopolysaccharide</p>
            <p class="work">长寿之神，延缓晒老</p>
          </div>
        </li>
        <li>
          <div class="libox">
            <p class="names">维生素、矿物营养</p>
            <p class="ennames">Vitamins, <br/>mineral nutrition</p>
            <p class="work">补脑佳品，老少皆宜</p>
          </div>
        </li>
        <li>
          <div class="libox">
            <p class="names">牛磺酸</p>
            <p class="ennames">Taurine</p>
            <p class="work">提高记忆力，增强活力预防心血	管病</p>
          </div>
        </li>
      </ul>
    </div>
    <div class="center">
      <div class="centerbg">
        <img src="../assets/thank.png" alt="">
      </div>
    </div>
    <Footers/>
  </div>
</template>
<script>
import Headers from './components/header'
import Footers from './components/footer'
export default {
  components: {
    Headers,
    Footers
  },
  data () {
   return {}
  },
  mounted() {},
  methods:{}
}
</script>
<style lang="scss" scoped>
.banner {
  width: 100%;
  height: 566px;
  background:url('./../assets/bg2.jpg') no-repeat center center;
  background-size: cover;
  text-align: center;
  color: #fff;
  overflow: hidden;
  .txt1 {
    font-size: 62px;
    margin-top: 110px;
  }
  .txt2 {
    font-size: 170px;
    height: 156px;
    line-height: 156px;
    font-weight: 200;
  }
  .txt3 {
    font-size: 80px;
    height: 75px;
    line-height: 75px;
    margin-top: 5px;
  }
  .txt4 {
    width: 511px;
    margin:0 auto;
    font-size: 20px;
    margin-top: 39px;
  }
}
.goodsintr {
  background: #F9F8F4;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #603E2D;
}
.goodsintr1 {
  .txt {
    width: 416px;
    margin-left: 84px;
    .names {
      font-size: 18px;
      font-weight: 600;
    }
    .ennames {
      font-size: 14px;
    }
    .te {
      margin-top: 30px;
      margin-bottom: 20px;
      p {
        font-size: 16px;
      }
    }
    .intr {
      font-size: 16px;
    }
  }
}
.goodsintrall {
  .txt {
    width: 332px;
    .btxt {
      text-align: center;
      font-size: 40px;
      margin-top: 10px;
    }
    .bred {
      text-align: center;
      font-size: 120px;
      color: #D71718;
      margin-top: 23px;
      line-height: 91px;
      height: 91px;
      // font-weight: 300;
    }
    .sred {
      font-size: 40px;
      color: #D71718;
    }
    .stxt {
      font-size: 16px;
      margin-top: 41px;
      margin-bottom: 80px;
    }
    .te {
      text-align: center;
      font-size: 35px;
    }
  }
}
.goodsintr2 {
  padding-top: 30px;
  padding-left:360px;
  .txt {
    margin-right: 48px;
  }
}
.goodsintr3 {
  padding-top: 112px;
  .txt {
    margin-left: 129px;
    .ste {
      margin-bottom: 0;
    }
    .stxt {
      margin-bottom:0 ;
    }
  }
}
.goodsintr4 {
  padding-top: 102px;
  .txt {
    margin-right: 114px;
    margin-top: 40px;
    .stxt {
      margin-bottom:0 ;
    }
  }
}
.quality {
  text-align: center;
  background: #F9F8F4;
  overflow: hidden;
  padding-top: 99px;
  img {
    width: 278px;
    margin: 0 12px;
  }
  p {
    font-size: 12px;
    transform: scale(0.83);
    margin-top: 18px;
    color: #603E2D;
  }
}
.composition {
  background: #F9F8F4;
  padding-top: 122px;
  padding-bottom: 242px;
  color: #603E2D;
  ul {
    display: flex;
    justify-content: center;
    li {
      text-align: center;
      border-right: 2px solid rgba($color: #000000, $alpha: .1);
      padding: 36px 24px;
      width: 200px;
      .names {
        font-size: 16px;
        font-weight: 700;
      }
      .ennames {
        font-size: 14px;
        margin-top: 6px;
        font-weight: 500;
      }
      .work {
        font-size: 16px;
        font-weight: 700;
        margin-top: 20px;
      }
    }
    li:last-child {border:0;width: 170px;}
  }
}
.center {
  padding-bottom: 140px;
  background: #E6E3DC;
  .centerbg {
    width: 100%;
    height: 468px;
    background: #000 url('./../assets/bg11.jpg') no-repeat center center;
    background-size: cover;
    position: relative;
    img {
      position: absolute;
      top: -138px;
      left:0;
      right:0;
      margin:0 auto;
    }
  }
}
@media screen and(max-width: 1600px){
  .goodsintr2 {
    padding-left: 160px;
  }
}
@media screen and(max-width: 1400px){
  .goodsintr1 {
    img {
      width: 450px;
    }
  }
  .goodsintr2 {
    padding-left: 100px;
    img {
      width: 620px;
    }
  }
  .goodsintr3 {
    img {
      width: 520px;
    }
  }
  .goodsintr4 {
    img {
      width: 520px;
    }
  }
  .center .centerbg img{
    width: 1100px;
    top: -90px;
  }
}
@media screen and(max-width: 1180px) {
  .center {
    .centerbg {
      img {
        width: 1024px;
        top: -70px;
      }
    }
  }
}
@media screen and(max-width: 768px) {
  .banner {
    height: 580px;
    background:url('./../assets/bg22.jpg') no-repeat center center;
    background-size: cover;
    .txt1 {
      font-size: 36px;
      margin-top: 125px;
    }
    .txt2 {
      font-size: 100px;
      height: 100px;
      line-height: 100px;
      font-weight: 200;
    }
    .txt3 {
      font-size: 50px;
      height: 50px;
      line-height: 50px;
      margin-top: 5px;
    }
    .txt4 {
      width: 309px;
      margin:0 auto;
      font-size: 16px;
      margin-top: 86px;
    }
  }
  .goodsintr {
    flex-wrap: wrap;
  }
  .goodsintrall {
    .txt {
      width: 332px;
      .btxt {
        text-align: center;
        font-size: 20px;
        margin-top: 0;
        margin-bottom: 20px;
      }
      .bred {
        text-align: center;
        font-size: 54px;
        color: #D71718;
        margin-top: 0;
        line-height: 75px;
        height: 75px;
        // font-weight: 300;
      }
      .sred {
        font-size: 20px;
        color: #D71718;
      }
      .stxt {
        width: 252px;
        margin:0 auto;
        font-size: 12px;
        margin-top: 0;
        margin-bottom: 10px;
      }
      .te {
        text-align: center;
        font-size: 20px;
      }
    }
  }
  .goodsintr1 {
    padding-bottom: 100px;
    img {
      width: 100%;
    }
    .txt {
      margin:0;
      padding:0 24px;
      .names {
        font-size: 16px;
      }
      .ennames {
        font-size: 14px;
        margin-top: 8px;
      }
      .te {
        margin-top: 20px;
        margin-bottom: 20px;
        p {
          font-size: 12px;
        }
      }
      .intr {
        font-size: 12px;
      }
    }
  }
  .goodsintr2 {
    flex-direction: column-reverse;
    padding-left: 0;
    padding-top: 0;
    img {
      width: 100%;
      margin:0;
    }
    .txt {
      margin: 0;
      margin-top: 50px;
      .stxt {
        margin-bottom: 50px;
      }
    }
  }
  .goodsintr3 {
    padding-left: 0;
    padding-top: 50px;
    img {
      width: 95%;
      margin:0;
    }
    .txt {
      margin:0;
      .bred {
        margin-top: 50px;
        line-height: 54px;
        height: 54px;
      }
    }
  }
  .goodsintr4 {
    padding-top: 0;
    flex-direction: column-reverse;
    padding-left: 0;
    img {
      width: 95%;
      margin:0;
    }
    .txt {
      margin:0;
      
    }
  }
  .quality {
    padding-top: 60px;
    padding:60px 25px 0;
    img {
      width: 100%;
      margin:0;
      margin-bottom: 24px;
    }
    p {
      text-align: left;
      font-size: 12px;
      margin-top: 0;
    }
  }
  .composition {
    padding-bottom: 134px;
    ul {
      flex-wrap: wrap;
      li {
        text-align: center;
        border-right: 0;
        // padding: 20px 19px;
        padding: 0;
        // width: 126px;
        width: 164px;
        height: 164px;
        .libox {
          padding: 37px 18px;
          margin-bottom: 10px;
        }
        .names {
          font-size: 15px;
          font-weight: 700;
        }
        .ennames {
          font-size: 12px;
          margin-top: 6px;
          font-weight: 500;
        }
        .work {
          font-size: 14px;
          font-weight: 700;
          margin-top: 20px;
        }
      }
      li:nth-child(1) {
        // border-right: 1px solid #E0DFDB;
        border-bottom: 1px solid #E0DFDB;
        .libox {
          border-right: 1px solid #E0DFDB;
        }
      }
      li:nth-child(2) {
        border-bottom: 1px solid #E0DFDB;
      }
      li:nth-child(3) {
        width: 100%;
      }
      li:nth-child(4) {
        border:0;
        // border-right: 1px solid #E0DFDB;
        border-top: 1px solid #E0DFDB;
        .libox {
          margin:0;
          margin-top: 10px;
          border-right: 1px solid #E0DFDB;
          padding: 24px 10px 32px;
        }
        .work {
          margin-top: 6px;
        }
      }
      li:last-child {
        width: 164px;
        border:0;
        border-top: 1px solid #E0DFDB;
        .libox {
          margin-top: 10px;
          padding: 24px 10px 32px;
        }
      }
    }
  }
  .center {
    padding-bottom: 0;
    background: #F9F8F4;
    .centerbg {
      width: 100%;
      height: 136px;
      background: #000 url('./../assets/bg11.jpg') no-repeat center center;
      background-size: cover;
      position: relative;
      // padding:0 12px;
      img {
        width: 100%;
        position: absolute;
        top: -45px;
        left:0;
        right:0;
        margin:0 auto;
      }
    }
  }
}
</style>